import classNames from "classnames"
import { isMobile } from "react-device-detect"
import useSiteStore from "store/site"

export function LexiretoCellTrapezoid(props) {
  const siteStore = useSiteStore()
  return (
    <>
      <div className='em-relative em-flex em-items-center em-justify-center em-h-12 em-px-3 em-space-x-3 em-bg-primary-games-light'>
        {props.alert}
        <div className='em-text-center em-text-[#2D7BB9] em-text-3xl'>
          <div className='em-flex-grow em-text-center em-uppercase'>
            {props.paraula}
          </div>
        </div>
      </div>
      <div className={classNames(
        'em-m-auto lexireto-container em-w-full',
        {
          'em-h-[450px]': (siteStore.site.name === 'THE NEWSPAPER' && !isMobile),
        }
      )}>
        <svg width='100%' height='auto' viewBox='0 0 500 500' fill='none'>
          <g className='em-fill-none em-uppercase'>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
              fontWeight='semi-bold'
            >
              <path
                stroke='none'
                className='em-text-[#89DE9C] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M135.016 249.26L192.508 149.681L307.492 149.681L364.984 249.26L307.492 348.839L192.508 348.839L135.016 249.26Z'
                fill='#89DE9C' />
              <text
                x='250'
                y='250'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letracentral}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M135.015 40.615L364.984 40.615L307.492 140.194H192.508L135.015 40.615Z'
                fill='#DFF5E5' />
              <text
                x='249'
                y='95'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[0]}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M488.184 244.517H373.2L315.708 144.937L373.2 45.358L400.006 90.1662L457.498 189.746L488.184 244.517Z'
                fill='#DFF5E5' />
              <text
                x='385'
                y='172'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[1]}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M373.2 453.162L315.708 353.583L373.2 254.003H488.184L465.516 293.082L408.024 392.661L373.2 453.162Z'
                fill='#DFF5E5' />
              <text
                x='385'
                y='332'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[2]}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M135.015 457.905L192.508 358.326H307.492L364.984 457.905H135.015Z'
                fill='#DFF5E5' />
              <text
                x='249'
                y='410'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[4]}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M126.8 453.162L97.6859 401.983L40.1937 302.403L11.8154 254.003L126.8 254.003L184.292 353.583L126.8 453.162Z'
                fill='#DFF5E5' />
              <text
                x='110'
                y='337'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[5]}</text>
            </g>
            <g onPointerDown={props.onClickLetter}
              className='em-cursor-pointer hover:em-text-white !em-font-[600] em-text-black'
            >
              <path
                stroke='none'
                className='em-text-[#DFF5E5] hover:!em-fill-[#2D7BB9] em-transition-all em-duration-300'
                d='M10.418 245.914L40.1937 196.916L97.6859 97.3363L125.402 46.7555L182.895 146.335L125.402 245.914H10.418Z'
                fill='#DFF5E5' />
              <text x='110'
                y='172'
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize='44'
                fontWeight='semi-bold'
              >{props._currentGameData.letters[6]}</text>
            </g>
          </g>
        </svg>
      </div>
    </>
  )
}
