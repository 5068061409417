import { IoMdShare } from 'react-icons/io'


export const en = {
  close: 'Close',
  by: 'By',
  play: 'Play',
  game: {
    historicalGames: {
      playing: 'PLAYING',
    },
    new: 'New',
    actions: {
      save: 'Save',
      download: 'Download',
      exit: 'Exit',
      help: 'Help'
    },
    timer: {
      show: 'Show',
      hide: 'Hide',
    },
    yourPoints: 'Your points',
    heptacracks: 'Heptacracks',
    yourWords: 'Your words',
    yourAnswers: 'Your answers',
    send: 'Send',
    tusclics: 'Your clicks',
    messages: {
      init: {
        message: 'Are you ready to start?',
        button: 'Play',
      },
      pause: {
        message: 'The game is paused',
        button: 'Continue',
      },
      restart: {
        message: 'You are about to restart the game',
        buttonPrimary: 'Restart',
        buttonCancel: 'Return',
      },
      resolve: {
        message: 'If you select RESOLVE, this game will appear as incomplete in My Stats. Do you agree?',
        buttonPrimary: 'Yes',
        buttonCancel: 'No',
      },
      save: {
        message: 'You are about to exit the game. Do you want to save the changes?',
        buttonPrimary: 'Yes',
        buttonCancel: 'No',
      },
      errorSaving: {
        message: 'Error saving. Try again.',
        button: 'Close',
      },
      errorRestarting: {
        message: 'Error restarting. Try again',
        button: 'Close',
      },
      errorResolving: {
        message: 'Error resolving. Try again.',
        button: 'Close',
      },
      errorFinishing: {
        message: 'Error finishing. Try again',
        button: 'Close',
      },
      errorChecking: {
        message: 'You have not completed the game correctly. Try again.',
        button: 'Close',
      },
      finished: {
        message: 'The game is completed. You can only check it.',
        button: 'Back',
      },
      complete: {
        title: 'Great!',
        endGameTitle: 'GAME OVER!',
        averageTime: 'Average time',
        completedTime: 'Completed game in',
        share: 'Share your results',
        shortDescription: 'Check if you have improved in My Stats.',
        description: 'You finished the game. Check if you have improved in My Stats.',
        button: 'Close',
        onDate: (date) => <>Game of {date}</>,
      },
      incomplete: {
        message: 'Here you have the results. It will appear as incomplete in My Stats.',
      },
      past: {
        message: 'Game over. You can only check it.',
        button: 'Back',
      },
      saved: {
        // message: 'It is not necessary to use the button Save. Every time you make a progress, the game will be automatically saved.',
        message: 'The game is saved correctly.',
        button: 'Exit',
      },
      savedlexireto: {
        message: ({ date }) => <>The game is saved correctly.</>,
        button: 'Exit',
      }
    },
    currentChallenge: {
      actions: {
        next: 'Next',
        showExplain: 'Show explanation',
        finish: 'Finish',
      },
      messages: {
        finish: {
          title: 'Completed test!',
          description: ({ corrects, errors }) => <>Correct answers {corrects}, incorrect {errors}.<br />You can check your results in My Stats.</>,
          button: 'Close',
        },
        showExplain: 'The reason of the answer',
      },
      statusEndGame: {
        good: 'GREAT!',
        neutral: 'GOOD!',
        bad: 'YOU CAN DO IT BETTER!',
      },
    },
    crosswords: {
      questions: 'Questions Crossword',
      horizontals: 'Horizontals',
      verticals: 'Verticals',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
      messages: {
        finish: {
          title: 'Great!',
          description: 'You finished the crossword.\n Check if you improved in the Stats.',
          button: 'Exit',
        },
        incomplete: {
          title: '',
          description: 'Here you have the crosswords resolved. They will appear as incomplete in the Stats.',
          button: 'Exit',
        },
      },
      actions: {
        checkLetter: 'Check letter',
        checkWord: 'Check word',
        checkCrossword: ({ gameName }) => <>Check {gameName}</>,
        resolveCrossword: 'Resolve',
        restartCrossword: 'Restart',
      }
    },
    sudoku: {
      candidates: 'Candidate',
      normal: 'Normal',
      levels: {
        easy: 'Basic',
        medium: 'Advanced',
        hard: 'Expert',
      },
      messages: {
      },
      actions: {
        checkCell: 'Check cell',
        checkSudoku: ({gameName}) => <>Check {gameName}</>,
        resolveSudoku: 'Resolve',
        restartSudoku: 'Restart',
      }
    },
    LettersGame: {
      title: "Letters",
      actions: {
        checkGame: "Check letters",
        resolveGame: "Resolve letters",
        restartGame: "Restart letters",
        apply: "Apply",
        clean: "Clean"
      },
      messages: {
        saved: {
          message: ({date}) => <>The game is saved correctly. You can finish it until the' <strong>{date}</strong></>
        },
        letters: "Letters",
        howToPlayTitle: "How to play",
        howToPlayText1:
        "Write all words you can using the given letters by selecting contiguous squares in all directions. Look at the examples.",
        howToPlayText2:
        "You can use each letter many times but not in the same word.",
        howToPlayTextFull:
        "Write all words you can using the given letters by selecting contiguous squares in all directions. Look at the examples. You can use each letter many times but not in the same word.",
        correctword: "Correct word!",
        wrongword: "Incorrect word!",
        repeatedword: "Repeated word!"
      }
    },
    tilesGame: {
      title: "Tiles",
      totalPairs: "Total pairs",
      resolvedPairs: "My pairs",
      marker: "Marker",
      resolveGame: "Resolve",
      restartGame: "Restart",
      howToPlay: "How to Play",
      howToPlayText: "Combine identical tiles in the shortest possible time. You have 4 minutes. Few tiles could be rotated.",
      howToPlayTextFull: () => (
        <>
          <strong>How to Play</strong>
          <br />
          Combine identical tiles in the shortest possible time. Few tiles could be rotated.
        </>
      ),
      incorrectlyPaired: "Incorrectly paired!",
      successfullyPaired: "Successfully paired!",
      messages: {
        init: '4 minutes to finish',
        '3m': '3 minutes to finish',
        '2m': '2 minutes to finish',
        '1m': '1 minute left',
        '30s': '30 seconds left',
        '10s': '10 seconds left',
      },
      incomplete: {
        title: 'WHAT A PITY!',
        message: 'You Have Run Out of Time',
      }
    },
    differencesGame: {
      title: "8 Mistakes",
      hits: "hits",
      clicks: "clicks",
      resolveGame: "Resolve",
      restartGame: "Restart",
      howToPlay: "How to play",
      howToPlayText: () => <>Find and mark the 8 mistakes in the second image. <br/>You have 12 opportunities.</>,
      howToPlayTextFull: () => (
        <>
          <strong>How to play</strong>
          <br />
          Find and mark the 8 mistakes in the second image. <br/>You have 12 opportunities.
        </>
      ),
      successfullyHit: "GREAT!",
      incorrectHit: "ERROR!",
      clickOtherImage: "Click on the second image",
      completedgame: "Completed game!",
      correcttext: "Correct clicks",
      incorrecttext: ", Incorrect clicks",
      textcheckresults: "You can check the results in My Stats",
      statusEndGame: {
        good: 'GREAT!',
        neutral: 'GOOD!',
        bad: 'YOU CAN IMPROVE!',
      }
    },
    LexiRetoGame: {
      expirationDate: 'today at 23:59:59',
      title: "Lexi Challenge",
      resolveGame: "Resolve",
      restartGame: "Restart",
      howToPlay: "How to play",
      howToPlayText: "How to play",
      howToPlayTextFull: () => (
        <>
          <div className="em-mb-1"><strong>How to play</strong></div>
          <ul>
            <li>Form words including a minimum of 3 letters. You can repeat letters, but always including the central one.</li>
            <li>Proper names, plurals and verbal forms other than infinitives are not allowed.</li>
            <li>Find words including 7 letters (Heptacrack!) and you will move up in the rankings and improve your statistics. </li>
            <li>Score: words including 3 letters give 1 point and those of 4 letters, 2 points. From 5 letters, you will obtain 1 point for each letter in the word. The heptacracks give 10 points. </li>
            <li>ATTENTION. You can only resolve the Lexi Challenges during the same day of their publication. At 12:00 pm the game becomes inactive and it's only possible to check it. </li>
          </ul>
        </>
      ),
      totalpalabras: "Your words",
      tuspalabras: "Your words",
      empiezanpor: "Start with:",
      encontradas: "Found",
      heptacrackencontradas: "Heptacracks",
      comovatujuego: "How was your game",
      comovatujuego_heptacrack: "Heptacrack!",
      comovatujuego_nuevonivel: "Move up position!",
      marcador: "Scoreboard",
      puntuacion: "Your points",
      maximo: "Maximum",
      encontrados: "Found",
      actions: {
        apply: "Apply",
        clean: "Clean",
        reordenar: "rearrange"
      },
      messages: {
        correctword: "Great!",
        wrongword: "Not found",
        repeatedword: "Repeated word",
        minimo3letras: "Minimum 3 letters",
        faltaletracentral: "The central letter is missing"
      },
      homenuevo : "NEW",
      statusEndGame: {
        faces_6_7: 'EXTRAORDINARY!',
        faces_4_5: 'EXCELENT!',
        faces_2_3: 'GOOD LEVEL!',
        faces_0_1: 'YOU CAN IMPROVE!',
      }

    },

    buttons: {
      statistics: 'My Statistics',
      share: () => <><IoMdShare /> <span>Share</span></>,
      shareLabel: 'Share',
    },
    words: {
      howToPlay: 'How to play',
      howToPlayContent: 'Discover the em-hidden word in 6 attempts. Each attempt must include 5 letters. After each attempt, the color of letters act as a guide in relation with the em-hidden word. Proper names, plurals and verbal forms others than infinitives are not allowed.',
      examples: {
        title: 'Examples',
        first: {
          word: 'CROSS',
          explanation: 'Letter C is included in the word and in the correct position.',
        },
        second: {
          word: 'MARCH',
          explanation: 'Letter R is included in the word and in the correct position.',
        },
        third: {
          word: 'GREEN',
          explanation: 'Letter E is not included.',
        },
      },
      messages: {
        filaincompleta: 'Incomplete Row',
        noencontrada: 'Not found!',
        palabracorrecta: "The correct word is:",
      },
      endGame: {
        puedesMejorar: 'YOU CAN IMPROVE!',
        insuperable: 'UNBEATABLE!',
        fantástico: 'FANTASTIC!',
        excelente: 'EXCELLENT!',
        bravo: 'BRAVO!',
      },
      footer: 'A NEW WORD EVERY DAY!',
      disclaimer: 'Wordly is an adaptation by Mind training of the original game created by John Wardle (@powerlanguish)',
      fourLetters: 'THE HIDDEN WORD 4',
      fiveLetters: 'THE HIDDEN WORD 5',
      sixLetters: 'THE HIDDEN WORD 6',
      choose: 'Choose',
    },
    quiz: {
      pregunta: 'Question',
    }
  },
}
