import {IoMdShare} from 'react-icons/io'

export const ge = {

    close: 'Schließen',
    by: 'von',
    play: 'Spielen',
    game: {
        historicalGames: {
            playing: 'AKTIVES SPIEL',
        },
        new: 'Neu',
        actions: {
            save: 'Speichern',
            download: 'Herunterladen',
            exit: 'Beenden',
            help: 'Hilfe'
        },
        timer: {
            show: 'Einblenden',
            hide: 'Ausblenden',
        },
        yourPoints: 'Deine Punkte',
        heptacracks: 'Heptacracks',
        yourWords: 'Deine Worte',
        yourAnswers: 'Deine Antworten',
        send: 'Absenden',
        tusclics: 'Deine Klicks',
        messages: {
            init: {
                message: 'Bist du bereit, loszulegen?',
                button: 'Spielen',
            },
            pause: {
                message: 'Das Spiel befindet sich im Pausemodus',
                button: 'Fortfahren',
            },
            restart: {
                message: 'Du bist dabei, das Spiel neu zu starten',
                buttonPrimary: 'Neu starten',
                buttonCancel: 'Zurück',
            },
            resolve: {
                message: 'Bei Auswahl der Option AUFLÖSEN wird dieses Spiel in „Meine Statistiken“ als unvollständig angezeigt. Bist du damit einverstanden?',
                buttonPrimary: 'Ja',
                buttonCancel: 'Nein',
            },
            save: {
                message: 'Du bist dabei, das Spiel zu beenden. Sollen die Änderungen gespeichert werden?',
                buttonPrimary: 'Ja',
                buttonCancel: 'Nein',
            },
            errorSaving: {
                message: 'Fehler beim Speichern. Versuche es noch einmal.',
                button: 'Schließen',
            },
            errorRestarting: {
                message: 'Fehler beim Neustart des Spiels. Versuche es noch einmal.',
                button: 'Schließen',
            },
            errorResolving: {
                message: 'Fehler beim Auflösen des Spiels. Versuche es noch einmal.',
                button: 'Schließen',
            },
            errorFinishing: {
                message: 'Fehler beim Beenden des Spiels. Versuche es noch einmal.',
                button: 'Schließen',
            },
            errorChecking: {
                message: 'Schade! Du hast das Spiel nicht korrekt abgeschlossen. Versuche es weiterhin.',
                button: 'Schließen',
            },
            finished: {
                message: 'Du hast das Spiel bereits abgeschlossen. Daher kannst du es nur anzeigen.',
                button: 'Zurück',
            },
            complete: {
                title: 'Bravo!',
                endGameTitle: 'SPIEL BEENDET!',
                averageTime: 'Durchschnittliche Gesamtzeit',
                percentageGeneralCompleted: 'Übersetzung ',
                completedTime: 'Spiel abgeschlossen',
                share: 'Teile deine Ergebnisse',
                shortDescription: 'Unter „Meine Statistiken“ kannst du überprüfen, ob du dich verbessert hast.',
                description: 'Du hast das Spiel beendet. Unter „Meine Statistiken“ kannst du überprüfen, ob du dich verbessert hast.',
                button: 'Schließen+',
                onDate: (date) => <>Spiel vom {date}</>,
            },
            incomplete: {
                message: 'So sieht die Auflösung des Spiels aus. Unter „Meine Statistiken“ wird das Spiel als unvollständig aufgeführt.',
            },
            past: {
                message: 'Das Spiel wurde bereits beendet. Du kannst lediglich die Auflösung anzeigen.',
                button: 'Zurück',
            },
            saved: {
                // message: 'Es ist nicht mehr notwendig, die Schaltfläche „Speichern“ zu verwenden. Das Spiel wird mit jedem weiteren Fortschritt automatisch gespeichert.',
                message: 'Das Spiel wurde ordnungsgemäß gespeichert',
                button: 'Verlassen',
            },
            savedlexireto: {
                message: ({date}) => <>Das Spiel wurde ordnungsgemäß gespeichert.</>,
                button: 'Verlassen',
            }
        },
        currentChallenge: {
            actions: {
                next: 'Weiter',
                showExplain: 'Erläuterung anzeigen',
                finish: 'Beenden',
            },
            messages: {
                finish: {
                    title: 'Test abgeschlossen!',
                    description: ({corrects, errors}) => <> Richtige Antworten {corrects}, falsche
                        Antworten {errors}.<br/>Unter „Meine Statistiken“ kannst du die kumulierten Ergebnisse
                        abrufen.</>,
                    button: 'Schließen',
                },
                showExplain: 'Begründung für die Antwort',
            },
            statusEndGame: {
                good: 'BRAVO!',
                neutral: 'NICHT SCHLECHT!',
                bad: 'DAS KANNST DU ABER BESSER!',
            },
        },
        crosswords: {
            questions: 'Fragen des Kreuzworträtsels',
            horizontals: 'Waagerecht',
            verticals: 'Senkrecht',
            horizontal: 'Waagerecht',
            vertical: 'Senkrecht',
            messages: {
                finish: {
                    title: 'Bravo!',
                    description: 'Du hast das Kreuzworträtsel gelöst.\nUnter „Meine  Statistiken“ kannst du überprüfen, ob du dich verbessert hast.',
                    button: 'Verlassen',
                },
                incomplete: {
                    title: '',
                    description: 'So sieht die Lösung des Kreuzworträtsels aus. Unter „Meine Statistiken“ wird das Spiel als unvollständig aufgeführt.',
                    button: 'Verlassen',
                },
            },
            actions: {
                checkLetter: 'Buchstaben prüfen',
                checkWord: 'Wort prüfen',
                checkCrossword: ({gameName}) => <>{gameName} prüfen</>,
                resolveCrossword: 'Auflösen',
                restartCrossword: 'Neu starten',
            }
        },
        sudoku: {
            normal: 'Normal',
            levels: {
                easy: 'für Einsteiger',
                medium: 'für Fortgeschrittene',
                hard: 'für Könner',
            },
            messages: {},
            actions: {
                checkCell: 'Kästchen prüfen',
                checkSudoku: ({gameName}) => <>{gameName} prüfen</>,
                resolveSudoku: 'Auflösen',
                restartSudoku: 'Neu starten',
            }
        },
        letrasConectadasGame: {
            title: "Wortschlangen",
            actions: {
                checkGame: "Wortschlangen prüfen",
                resolveGame: "Wortschlangen auflösen",
                restartGame: "Wortschlangen neu starten",
                apply: "Anwenden",
                clean: "Löschen"
            },
            messages: {
                saved: {
                    message: ({date}) => <>Das Spiel wurde ordnungsgemäß gespeichert. Du kannst es bis
                        zum <strong>{date}</strong> fertigspielen.</>
                },
                letters: "Wortschlangen",
                howToPlayTitle: "Spielanleitung",
                howToPlayText1:
                    "Schreibe alle Wörter auf, die du aus den Buchstaben im Quadrat bilden kannst. Dabei darfst du nur die Buchstaben in den waagerecht bzw. senkrecht angrenzenden Kästchen verwenden, nicht aber in den diagonal angrenzenden. Orientiere dich an den Beispielen.",
                howToPlayText2:
                    "Jeder Buchstabe kann beliebig oft verwendet werden, nicht jedoch in demselben Wort.",
                howToPlayTextFull:
                    "Schreibe alle Wörter auf, die du aus den Buchstaben im Quadrat bilden kannst. Dabei darfst du nur die Buchstaben in den waagerecht bzw. senkrecht angrenzenden Kästchen verwenden, nicht aber in den diagonal angrenzenden. Orientiere dich an den Beispielen. Beachte Folgendes: Jeder Buchstabe kann beliebig oft verwendet werden, nicht jedoch in demselben Wort.",
                correctword: "Wort richtig!",
                wrongword: "Wort falsch!",
                repeatedword: "Wort bereits vorhanden!"
            }
        },
        tilesGame: {
            title: "Kacheln",
            totalPairs: "Gesamtzahl von Kacheln",
            resolvedPairs: "Meine Kachelpaare",
            marker: "Zähler",
            resolveGame: "Auflösen",
            restartGame: "Neu starten",
            howToPlay: "Spielanleitung",
            howToPlayText: "Finde die jeweils identischen Kacheln, um alle Kacheln im Spielfeld vor Ablauf der Spielzeit zuzuordnen. Du hast 4 Minuten Zeit! Beachte, dass einige Kacheln möglicherweise gedreht sind.",
            howToPlayTextFull: () => (
                <>
                    <strong>Spielanleitung</strong>
                    <br/>
                    Finde die jeweils identischen Kacheln, um alle Kacheln im Spielfeld vor Ablauf der Spielzeit
                    zuzuordnen. Dabei musst du genau hinsehen, denn einige Kacheln sind möglicherweise gedreht.
                </>
            ),
            incorrectlyPaired: "Falsches Kachelpaar!",
            successfullyPaired: "Übereinstimmendes Kachelpaar!",
            messages: {
                init: 'Du hast 4 Minuten Zeit, um das Spiel abzuschließen',
                '3m': 'Du hast noch 3 Minuten Zeit',
                '2m': 'Du hast noch 2 Minuten Zeit',
                '1m': 'Du hast noch eine Minuten Zeit',
                '30s': 'Dir bleiben noch 30 Sekunden',
                '10s': 'Dir bleiben noch 10 Sekunden',
            },
            incomplete: {
                title: 'SCHADE!',
                message: 'Die Zeit ist abgelaufen',
            }
        },
        differencesGame: {
            title: "8 Fehler",
            hits: "Treffer",
            clicks: "Klicks",
            resolveGame: "Auflösen",
            restartGame: "Neu starten",
            howToPlay: "Spielanleitung",
            howToPlayText: () => <>Finde und markiere die 8 Fehler im zweiten Bild. <br/>Du hast insgesamt 12
                Versuche.</>,
            howToPlayTextFull: () => (
                <>
                    <strong>Spielanleitung</strong>
                    <br/>
                    Finde und markiere die 8 Fehler im zweiten Bild. <br/>Du hast insgesamt 12 Versuche.
                </>
            ),
            successfullyHit: "RICHTIG!",
            incorrectHit: "FALSCH!",
            clickOtherImage: "Klicke im zweiten Bild auf die Fehler",
            completedgame: "Spiel abgeschlossen!",
            correcttext: "Richtige Klicks ",
            incorrecttext: ", falsche Klicks ",
            textcheckresults: "Die kumulierten Ergebnisse werden unter „Meine Statistiken“ angezeigt",
            statusEndGame: {
                good: 'BRAVO!',
                neutral: 'NICHT SCHLECHT!',
                bad: 'DAS KANNST DU ABER BESSER!',
            }
        },
        LexiRetoGame: {
            expirationDate: 'heute um 23:59:59',
            title: "Buchstasieben",
            resolveGame: "Auflösen",
            restartGame: "Neu starten",
            howToPlay: "Spielanleitung",
            howToPlayText: "Spielanleitung",
            howToPlayTextFull: () => (
                <>
                    <div className="mb-1"><strong>Spielanleitung</strong></div>
                    <ul>
                        <li>Bilde Wörter mit mindestens 3 Buchstaben. Du kannst Buchstaben zwar wiederholen, aber
                            der mittlere Buchstabe muss immer verwendet werden.
                        </li>
                        <li>Eigennamen, Pluralformen und konjugierte Verbformen sind nicht zulässig; es dürfen nur
                            Infinitive angegeben werden.
                        </li>
                        <li>Finde Wörter, die alle 7 Buchstaben enthalten (Heptacrack) und du steigst unter „Das
                            hast du drauf“ auf und verbesserst deine Statistiken.
                        </li>
                        <li>Punktevergabe: Für Wörter mit 3 Buchstaben kriegst du 1 Punkt und für Wörter mit 4
                            Buchstaben bekommst du 2 Punkte. Wörter mit 5 oder mehr Buchstaben bringen dir so viele
                            Punkte ein, wie das Wort lang ist. Für Heptacracks, d. h. für Wörter, die alle 7
                            Buchstaben enthalten, werden dir 10 Punkte angerechnet.
                        </li>
                        <li>BEACHTE FOLGENDES: Du kannst die Buchstasieben-Rätsel immer nur am Tag ihrer
                            Veröffentlichung lösen. Um Mitternacht wird das Spiel jeweils inaktiviert und du kannst
                            es nur noch zu Referenzzwecken anzeigen.
                        </li>
                    </ul>
                </>
            ),
            totalpalabras: "Deine Wörter",
            tuspalabras: "Deine Wörter",
            empiezanpor: "Beginnen mit: ",
            encontradas: "Gefunden: ",
            heptacrackencontradas: "Heptacracks",
            comovatujuego: "Das hast du drauf",
            comovatujuego_heptacrack: "Heptacrack!",
            comovatujuego_nuevonivel: "Du steigst auf!",
            marcador: "Zähler",
            puntuacion: "Deine Punkte",
            maximo: "Maximal",
            encontrados: "Gefunden",
            actions: {
                apply: "Anwenden",
                clean: "Löschen",
                reordenar: "Mischen"
            },
            messages: {
                correctword: "Richtig!",
                wrongword: "Nicht gefunden",
                repeatedword: "Bereits angegeben",
                minimo3letras: "Mindestlänge 3 Buchstaben",
                faltaletracentral: " Der mittlere Buchstabe fehlt"
            },
            homenuevo: "NEU",
            statusEndGame: {
                faces_6_7: 'HERVORRAGEND!',
                faces_4_5: 'SEHR GUT!',
                faces_2_3: 'NICHT SCHLECHT!',
                faces_0_1: 'DAS KANNST DU ABER BESSER!',
            }

        },

        buttons: {
            statistics: 'Meine Statistiken',
            share: () => <><IoMdShare/> <span>Teilen</span></>,
            shareLabel: 'Teilen',
        },
        words: {
            howToPlay: 'Spielanleitung',
            howToPlayContent: 'Ziel ist es, das versteckte Wort in 6 Versuchen herauszufinden. Für jeden Versuch muss ein gültiges Wort aus 5 Buchstaben angegeben werden. Nach jedem Versuch erhältst du anhand der Farbe der Buchstaben Hinweise auf das versteckte Wort. Eigennamen sind nicht zulässig.',
            examples: {
                title: 'Beispiele',
                first: {
                    word: 'SUCHE',
                    explanation: 'Der Buchstabe S ist im Lösungswort enthalten und befindet sich an der richtigen Stelle.',
                },
                second: {
                    word: 'KARTE',
                    explanation: 'Der Buchstabe R ist zwar im Lösungswort enthalten, befindet sich aber an der falschen Stelle.',
                },
                third: {
                    word: 'GERNE',
                    explanation: 'Der Buchstabe E kommt im Lösungswort überhaupt nicht vor.',
                },
            },

            messages: {
                filaincompleta: 'Unvollständige Zeile',
                noencontrada: 'Nicht gefunden!',
                palabracorrecta: "Das richtige Wort ist:",

            },
            endGame: {
                puedesMejorar: 'Sie können sich verbessern',
                insuperable: 'Unübertroffen!',
                fantástico: 'Fantastisch!',
                excelente: 'Ausgezeichnet!',
                bravo: 'BRAVO!',
            },
            footer: 'JEDEN TAG EIN NEUES WORT!',
            disclaimer: 'WordleMente ist eine Mind-Training-Adaption des Originalspiels, das quelloffen von Josh Wardle (@powerlanguish) entwickelt wurde.',
            fourLetters: 'THE HIDDEN WORD 4',
            fiveLetters: 'THE HIDDEN WORD 5',
            sixLetters: 'THE HIDDEN WORD 6',
            choose: 'Wählen',
        },
        quiz: {
            pregunta: 'Frage',
        }
    },
}
