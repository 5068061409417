import { IoMdShare } from 'react-icons/io'

import LetrasConectadasCA from 'scenes/LetrasConectadas/locales/ca/translation.js'
import TilesGameLocaleCA from  'scenes/Tiles/locales/ca/translation.js'
import DifferencesGameLocaleCA from 'scenes/Differences/locales/ca/translation.js'
import LexiRetoGameLocaleCA from 'scenes/Lexireto/locales/ca/translation.js'


export const ca = {
  close: 'Tancar',
  by: 'Per',
  play: 'Juga',
  game: {
    historicalGames: {
      playing: 'EN JOC',
    },
    new: 'Nou',
    actions: {
      save: 'Guardar',
      download: 'Descarregar',
      exit: 'Sortir',
      help: 'Ajuda'
    },
    timer: {
      show: 'Mostrar',
      hide: 'Amagar',
    },
    yourPoints: 'Puntuació',
    yourAnswers: 'Les teves respostes',
    send: 'Enviar',
    heptacracks: 'Heptacracks',
    yourWords: 'Paraules',
    tusclics: 'Els teus clics',
    messages: {
      init: {
        message: 'Estàs preparat per començar?',
        button: 'Jugar',
      },
      pause: {
        message: 'El joc està en pausa',
        button: 'Continuar',
      },
      restart: {
        message: 'Estàs a punt de tornar a començar el joc.',
        buttonPrimary: 'Reiniciar',
        buttonCancel: 'Tornar',
      },
      save: {
        message: 'Estàs a punt de sortir del joc. Vols guardar els canvis?',
        buttonPrimary: 'Si',
        buttonCancel: 'No',
      },
      resolve: {
        message: 'Si tries RESOLDRE, aquest joc quedarà reflectit com a incomplet a Les meves estadístiques. Estàs d’acord?',
        buttonPrimary: 'Si',
        buttonCancel: 'No',
      },
      errorChecking: {
        message: 'Llàstima! No has completat el joc correctament. Continua provant.',
        button: 'Tancar',
      },
      finished: {
        message: 'Ja has completat aquest joc. Només ho pots consultar.',
        button: 'Tancar',
      },
      incomplete: {
        message: 'Aquí tens el joc resolt. A Les meves estadístiques es comptabilitzarà com a incomplet.',
      },
      complete: {
        title: 'MOLT BÉ!',
        endGameTitle: "JOC COMPLETAT",
        averageTime: 'Mitjana de temps general',
        completedTime: 'Joc completat en',
        share: 'Comparteix els teus resultats',
        shortDescription: 'Comprova si has millorat en Les meves estadístiques.',
        description: 'Has completat el joc. Comprova si has millorat en Les meves estadístiques.',
        button: 'Tancar',
        onDate: (date) => <>Joc del {date}</>,
      },
      saved: {
        // message: 'Ja no cal utilitzar el botó Desa. Cada cop que facis un avenç, el joc quedarà automàticament guardat.',
        message: "El joc s'ha guardat correctament.",
        button: 'Tancar',
      }
    },
  currentChallenge: {
      actions: {
        next: 'Següent',
        showExplain: 'Veure explicació',
        finish: 'Finalitzar',
      },
      messages: {
        finish: {
          title: '¡Test completado!',
          description: ({ corrects, errors }) => <>Respuestas correctas {corrects}, incorrectas {errors}.<br />Puedes revisar en Mis estadísticas los resultados acumulados.</>,
          button: 'Cerrar',
        },
        showExplain: 'El perquè de la resposta',
      },
      statusEndGame: {
        good: '¡BRAVO!',
        neutral: 'BÉ!',
        bad: 'POTS MILLORAR!',
      },
    },
    crosswords: {
      questions: 'Preguntas Crucigrama',
      horizontals: 'Horitzontals',
      verticals: 'Verticals',
      horizontal: 'Horitzontal',
      vertical: 'Vertical',
      messages: {
        finish: {
          title: '¡Bravo!',
          description: 'Vas acabar els teus mots encreuats.\nComprova si vas millorar en les estadístiques.',
          button: 'Sortir',
        },
        incomplete: {
          title: '',
          description: 'Aquí tens els mots encreuats resolts. A Les meves estadístiques es comptabilitzaran com a incomplets.',
          button: 'Sortir',
        },
      },
      actions: {
        checkLetter: 'Comprovar lletra',
        checkWord: 'Comprovar paraula',
        checkCrossword: ({gameName}) => <>Comprovar {gameName}</>,
        resolveCrossword: 'Resoldre',
        restartCrossword: 'Reiniciar',
      }
    },
    sudoku: {
      candidates: 'Possibles',
      normal: 'Normal',
      levels: {
        easy: 'Bàsic',
        medium: 'Avançat',
        hard: 'Expert',
      },
      messages: {
      },
      actions: {
        checkCell: 'Comprovar casella',
        checkSudoku: ({gameName}) => <>Comprovar {gameName}</>,
        resolveSudoku: 'Resoldre',
        restartSudoku: 'Reiniciar',
      }
    },

    letrasConectadasGame: LetrasConectadasCA,
    tilesGame: TilesGameLocaleCA,
    differencesGame: DifferencesGameLocaleCA,
    LexiRetoGame: LexiRetoGameLocaleCA,

    buttons: {
      statistics: 'Les meves estadístiques',
      share: () => <><IoMdShare /> <span>Compartir</span></>,
      shareLabel: 'Compartir',
    },
    words: {
      howToPlay: 'Com jugar-hi',
      howToPlayContent: 'Descobreix la paraula oculta en 6 intents. Cada intent ha de ser una paraula vàlida de 5 lletres. Després de cada intent el color de les lletres t\'ofereix pistes sobre la paraula oculta. No s\'admeten noms propis, plurals i formes verbals conjugades (només infinitius).',
      examples: {
        title: 'Exemples',
        first: {
          word: 'COURE',
          explanation: 'La lletra C és a la paraula i al lloc correcte.',
        },
        second: {
          word: 'NEGRE',
          explanation: 'La lletra G és a la paraula però en un altre lloc.',
        },
        third: {
          word: 'RADIO',
          explanation: 'La lletra O no está a la paraula.',
        },
      },
      messages: {
        filaincompleta: 'Fila Incompleta',
        noencontrada: 'No trobada!',
        palabracorrecta: "La paraula correcta és:",
      },
      endGame: {
        puedesMejorar: 'POTS MILLORAR!',
        insuperable: 'INSUPERABLE!',
        fantástico: 'FANTÀSTIC!',
        excelente: 'EXCELENT!',
        bravo: 'BRAVO!',
      },
      footer: 'UNA PARAULA NOVA CADA DIA!',
      disclaimer: 'El Mot és una adaptació de Mind Training del joc original creat per Josh Wardle (@powerlanguish). Agraïments: Institut d\'Estudis Catalans',
      fourLetters: 'THE HIDDEN WORD 4',
      fiveLetters: 'THE HIDDEN WORD 5',
      sixLetters: 'THE HIDDEN WORD 6',
      choose: 'Triar',
    },
    quiz: {
      pregunta: 'Pregunta',
    }
  },
}
