import I18n from 'lang'
import React from 'react'
import classNames from 'classnames'
import useSiteStore from 'store/site'

import { LexiretoCell } from './LexiretoCell'
import { LexiretoCellTrapezoid } from './LexiretoCell-trapezoid'
import { isMobile } from 'react-device-detect'


const CellBySite = ({design, ...props}) => {
  const { site } = useSiteStore()
  switch (site.name) {
    case 'lavanguardia.com':
    case 'THE NEWSPAPER':
      return <LexiretoCellTrapezoid {...props} />
    default:
      return <LexiretoCell {...props} />
  }
}


export default function LexiretoBoard(props) {
  const { site } = useSiteStore()
  return (
    <div className={classNames(
      'em-flex-grow-0 em-pb-4 em-mx-auto',
      {
        'em-mr-[30px]': (site.name === 'THE NEWSPAPER' && !isMobile),
      }
      )}>
      <CellBySite {...props} />
      <div className=' lr-container-buttons'>
        <button
          className='lc-button lc-button-reset'
          type='button'
          onClick={(e) => { props.onClickBorrar(e) }}
        >
          <I18n t='game.LexiRetoGame.actions.clean' />
        </button>
        <button
          type='button'
          onClick={() => { props.onClickReorder() }}
          className='em-inline-flex em-items-center em-justify-center em-p-3 em-ml-4 em-mr-4 em-align-top em-transition-colors em-duration-150 em-rounded-full lc-button'
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='18.841' height='17' viewBox='0 0 18.841 21.533'><g transform='translate(-1.999 -0.284)'><path fill='#555' d='M11.42,3.57A7.85,7.85,0,1,1,4.283,8.145.785.785,0,0,0,2.858,7.49,9.42,9.42,0,1,0,11.42,2Z' transform='translate(0 0.978)' /><path fill='#555' d='M9.63,6.849V.676a.392.392,0,0,0-.644-.3L5.281,3.462a.392.392,0,0,0,0,.6L8.986,7.151a.392.392,0,0,0,.644-.3Z' transform='translate(1.79)' /></g></svg>
        </button>
        <button
          className='lc-button lc-button-apply'
          type='button'
          onClick={(e) => { props.onClickAply(e) }}
        >
          <I18n t='game.LexiRetoGame.actions.apply' />
        </button>
      </div>
    </div>
  )
}
