export const gamesBasePathname = window.location.pathname.split(/\/(es|ca|en|ar|br|ge)\/?/i)[0]
export const gamesBasePathnameAssets = process.env.REACT_APP_BASE_PATHNAME_ASSETS || ''

export const getCompletePathname = (pathname) => {

  if (pathname.startsWith('/assets')) {
    return gamesBasePathnameAssets + pathname
  }

  return gamesBasePathname + pathname
}
