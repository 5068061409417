import classNames from 'classnames'
import { isDesktop, isMobile } from 'react-device-detect'


export function BackgroundRowComponent(notFound, finished, attempts, solution, currentWord, index, wIndex, CellComponent, siteStore) {

  return (
    <>
      <div
        key={`words_row_${wIndex}`}
        className={classNames(
          'em-flex em-space-x-1 em-justify-center em-perspective em-items-center',
        )}
      >
        <span
          className={classNames(
            'em-bg-disabled em-w-5 em-h-5 em-rounded-full em-text-white em-justify-center em-items-center em-flex em-mr-1 sm:em-mr-5',
            { '!em-bg-[#F5F5F5] !em-text-[#AAAAAA]': !(index === 0) && ( siteStore.site.name === 'lavanguardia.com' || siteStore.site.name === 'THE NEWSPAPER' ) }
          )}
        >{wIndex + 1}</span>
          <div className={classNames(
            'em-flex em-flex-row em-py-2',
            {
              '!em-bg-[#555555]': index === 0,
            }
          )}>
            {
              Array(solution.length).fill().map((_, lIndex) => (
                <CellComponent
                  notFound={notFound && index === 0}
                  key={`words_cell_${wIndex}_${lIndex}`}
                  delayIndex={lIndex}
                  letter={(index === 0 && currentWord?.[lIndex]) || ''}
                  indexActive={
                    !finished &&
                    index === 0 &&
                    currentWord?.length === lIndex
                  }
                  lastCell={lIndex === solution.length - 1}
                  firstCell={lIndex === 0}
                  rowSelected={index === 0}
                />
              ))
            }
          </div>
      </div>
      {/* Separator */}
      <svg
        className={classNames(
          'em-w-full em-bg-disabled-lighter',
          {
            '!em-w-[237px] !em-ml-[57px]': isMobile,
            '!em-w-[53%] em-ml-[147px]': isDesktop,
          }
        )}
        height={1}
        viewBox='0 0 100 1'
      >
        <rect width='100' stroke='gray' opacity='20%' />
      </svg>
    </>
  )
}
