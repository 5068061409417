import React from 'react'
import propTypes from 'prop-types'
import { useParams } from 'react-router'
import moment from 'moment'
import { HiOutlineBackspace } from 'react-icons/hi'
import useSiteStore from 'store/site'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import Game, {
  Alert,
  Overflow,
  STATUS_FINISHED,
  STATUS_PLAYING,
} from 'layouts/Game'
import Timer from 'components/Timer'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import useGame from 'hooks/useGame'

import Grid from 'scenes/Words/Grid'
import useGameStore from 'store'
import { getStatuses } from './lib/statuses'
import Buttonless from 'components/Buttonless'
import classNames from 'classnames'
import Cell from './Cell'
import BorderlessCell from './borderless-Cell'

import I18n from 'lang'


const lettersEs = ' qwertyuiop  asdfghjklñ +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersEn = ' qwertyuiop  asdfghjkl +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersPt = ' qwertyuiop  asdfghjklç +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersCa = ' qwertyuiop  asdfghjklç +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersFr = ' qwertyuiop  asdfghjklç +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersGe = 'qwertzuiopü asdfghjklöä +yxcvbnm-'.toUpperCase().split('').map(key => ({ key }))
const lettersPl = 'qwertyuiop asdfghjkl +zxcvbnm- ąćęłńóśźż'.toUpperCase().split('').map(key => ({ key }))
const letters = {
  ar: lettersEs,
  es: lettersEs,
  pt: lettersPt,
  br: lettersPt,
  en: lettersEn,
  ca: lettersCa,
  ge: lettersGe,
  pl: lettersPl,
  fr: lettersFr
}

function Keyboard(props) {
  const {
    onClick,
    lettersStatus = {},
    className = '',
  } = props
  const siteStore = useSiteStore()
  const { locale } = useParams()

  const sitesBorderlessCell = ['lavanguardia.com', 'THE NEWSPAPER']

  return (
    <div className='em-sticky em-bottom-0 em-z-30 em-mt-10'>
      {props.children}
      <div
        className={classNames(
          'em-bg-white -em-mx-6 md:em-mx-0 em-mb-4 em-grid em-grid-cols-10 md:em-grid-cols-12 em-gap-x-1 em-gap-y-2',
          {
            '!em-grid-cols-11': locale === 'ge' || locale === 'pl',
          },
          className,
        )}
        style={{
          boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.1)',
        }}
      >
        {
          letters[locale].map(
            (key, index) => {
              if (
                key.key === '+' ||
                key.key === '-'
              ) {
                return (
                  <Buttonless
                    key={key.key === '+' ? 'Enter' : 'Backspace'}
                    className={classNames(
                      'em-uppercase md:em-text-xl',
                      {
                        'md:em-col-span-1': key.key === '-',
                        'em-col-span-2 md:em-col-span-3': key.key === '+',
                        '!em-col-span-3': (locale === 'ge' || locale === 'pl') && key.key === '+',
                        '!em-col-span-1': (locale === 'ge' || locale === 'pl') && key.key === '-',
                      },
                      'em-flex em-items-center em-justify-center',
                      '!em-h-14',
                      '!em-font-semibold',
                      'em-text-disabled em-bg-disabled-lightest !em-fill-transparent',
                      'hover:em-bg-primary hover:em-text-white',
                      'em-rounded',
                      {
                        '!em-bg-[#31A742] em-text-white': (lettersStatus[key.key] === 'exact' && !sitesBorderlessCell.includes(siteStore.site.name)),
                        '!em-bg-[#F0C21C] em-text-white': (lettersStatus[key.key] === 'included' && !sitesBorderlessCell.includes(siteStore.site.name)),
                        '!em-bg-[#86845E] em-text-white': (lettersStatus[key.key] === 'wrong' && !sitesBorderlessCell.includes(siteStore.site.name)),
                      },
                      {
                        '!em-bg-[#5FC66E] !em-text-black': lettersStatus[key.key] === 'exact' && sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-[#F0C21C] !em-text-black': lettersStatus[key.key] === 'included' && sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-red-300 !em-text-black': lettersStatus[key.key] === 'wrong' && sitesBorderlessCell.includes(siteStore.site.name),
                      }
                    )}
                    onClick={() => {
                      onClick(key.key === '+' ? 'Enter' : 'Backspace')
                    }}
                  >
                    {
                      key.key === '-' ? (
                        <HiOutlineBackspace />
                      ) : (
                        <I18n t='game.send' />
                      )
                    }
                  </Buttonless>
                )
              }
              return (
                key.key !== ' ' ? (
                  <Buttonless
                    key={key.key}
                    className={classNames(
                      'em-uppercase md:em-text-3xl',
                      'em-flex em-items-center em-justify-center',
                      '!em-h-14',
                      '!em-font-semibold',
                      'em-text-disabled em-bg-disabled-lightest !em-fill-transparent',
                      'hover:em-bg-primary hover:em-text-white',
                      'em-rounded',
                      {
                        '!em-bg-[#31A742] em-text-white': lettersStatus[key.key] === 'exact' && !sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-[#F0C21C] em-text-white': lettersStatus[key.key] === 'included' && !sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-[#86845E] em-text-white': lettersStatus[key.key] === 'wrong' && !sitesBorderlessCell.includes(siteStore.site.name),
                      },
                      {
                        '!em-bg-[#5FC66E] !em-text-black': lettersStatus[key.key] === 'exact' && sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-[#F0C21C] !em-text-black': lettersStatus[key.key] === 'included' && sitesBorderlessCell.includes(siteStore.site.name),
                        '!em-bg-[#AAAAAA] !em-text-black': lettersStatus[key.key] === 'wrong' && sitesBorderlessCell.includes(siteStore.site.name),
                      }
                    )}
                    onClick={() => {
                      onClick(key.key)
                    }}
                  >
                    {key.key}
                  </Buttonless>
                ) : (
                  <div key={key.key + '_' + index} className={classNames(
                    'em-hidden em-h-14 md:em-block',
                    {
                      '!em-hidden': locale === 'ge',
                    }
                  )} />
                )
              )
            },
          )
        }
      </div>
    </div>
  )
}


function WordsInner(props) {
  const { gameProps } = props
  const { setToast } = useGameStore()

  const {
    title,
    color: bgColor,
    icon: {
      big: bigIconSrc,
    },
    api: {
      basepath: baseUrlApi,
      attrs: {
        level = undefined,
      } = {},
    },
    url: baseUrl,
  } = gameProps

  const {
    id = 'last',
    locale,
  } = useParams()

  const [words, setWords] = React.useState({})
  const [howToPlay, setHowToPlay] = React.useState(false)
  const [, _forceUpdate] = React.useState({})
  const [endGameModalContent, _setEndGameModalContent] = React.useState(null)
  const [statistics, setStatistics] = React.useState([])

  const forceUpdate = React.useCallback(
    async () => {
      if (save && words.id) {
        saveGame(false, '', true)
      }
      if (attempts.current.length === 6) {
        await finishGame()
      }
      _forceUpdate({})
    },
    [words.id] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [word, setWord] = React.useState('')
  const lettersStatus = React.useRef(letters[locale].reduce(
    (acc, letter) => ({
      ...acc,
      [letter.key]: '',
    }),
    {},
  ))
  const [notFound, setNotFound] = React.useState(false)
  const currentWord = React.useRef('')
  const attempts = React.useRef([])
  const setCurrentWord = React.useCallback((word) => {
    currentWord.current = word
    forceUpdate()
  }, [forceUpdate])

  const setEndGameModalContent = (attempts, word) => {
    let title = I18n.getTranslation({pathname: '/' + locale}, 'game.words.endGame.puedesMejorar')
    if (attempts.includes(word)) {
      switch (attempts.length) {
        case 1:
          title = I18n.getTranslation({pathname: '/' + locale}, 'game.words.endGame.insuperable')
          break
        case 2:
          title = I18n.getTranslation({pathname: '/' + locale}, 'game.words.endGame.fantástico')
          break
        case 3:
        case 4:
          title = I18n.getTranslation({pathname: '/' + locale}, 'game.words.endGame.excelente')
          break
        case 5:
        case 6:
          title = I18n.getTranslation({pathname: '/' + locale}, 'game.words.endGame.bravo')
          break
        default:
          break
      }
    }

    _setEndGameModalContent(
      <div className='em-flex em-flex-col em-justify-center em-space-y-5 em-text-yellow'>
        <div className='em-text-3xl em-text-center em-uppercase'>
          {/* <I18n t='game.messages.complete.title' /> */}
          {title}
        </div>
        <div className='em-flex em-flex-col em-space-y-2 em-scale-50 !-em-m-[15%] md:!-em-m-[25%]'>
          {
            Array(6).fill()?.map((_, wIndex) => {
              const attempt = attempts[wIndex] || null
              const statuses = getStatuses(attempt, word)
              return (
                <div key={`words_row_${wIndex}`} className='em-flex em-justify-center em-space-x-2'>
                  {
                    Array(word.length).fill()?.map((_, lIndex) => (
                      <Cell
                        className={classNames(
                          {
                            '!em-border-none': !!attempt,
                          }
                        )}
                        key={`end_game_words_cell_${wIndex}_${lIndex}`}
                        delayIndex={lIndex}
                        status={statuses[lIndex]}
                        letter={''}
                      />
                    ))
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const {
    initGame,
    setPause,
    setPlaying,

    // actions
    finish,
    save,
    autoSave,

    // variables
    status,
    time,
    message,
    modal,
    isLoading,

    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    startFrom: parseInt(words?.estadouser?.tiempo || 0, 10),
    statusInit: words?.estadouser?.id,
    expirationDate: words?.despublicado,
    locale: locale,
    onKeyUp: async (e) => {
      if (e.key === 'Escape') {
        setPause()
      }

      if (e.key === 'Enter') {
        setPlaying()
      }

      if (
        e.key === 'Enter' ||
        (
          e?.keyCode && e.keyCode >= 65 && e.keyCode <= 90
        ) ||
        (
          e.key === 'Backspace' &&
          currentWord.current.length > 0
        ) ||
        [
          'Ñ', // ES
          'Ç', // BR, CA
          'Ä', 'Ö', 'Ü', // GE
        ].includes(e.key.toUpperCase())
      ) {
        await changeItem(e.key)
      }
    },
    waitToShowEndGameModal: 3000,
  })

  const fetchStatistics = async () => {
    const url = `/user/stats/${baseUrlApi}`
    const response = await gamesApi.post(
      url,
      {
        level,
      },
    )

    setStatistics(response.data)
  }

  const fetchGame = async () => {
    const _id = Number(id) ? id : 'last'

    const response = await gamesApi.get(
      `${baseUrlApi}/get/${_id}`,
      {
        params: {
          level,
        },
      },
    )
    const dataFetch = response.data
    attempts.current = dataFetch?.estadouser?.palabras || []

    setEndGameModalContent(
      attempts.current,
      dataFetch.palabra.toUpperCase(),
    )

    setWord(dataFetch.palabra.toUpperCase())

    setLettersStatus(attempts.current, dataFetch.palabra.toUpperCase())

    setWords(dataFetch)

  }

  const checkWord = async (word) => {
    try {
      const response = await gamesApi.get(
        `${baseUrlApi}/checkword`,
        {
          params: {
            level,
            word: word,
            id,
          },
        }
      )

      if (response.data === 'true') {
        return true
      }

      throw new Error('Word not found')

    } catch (error) {
      return false
    }
  }

  const finishGame = async () => {
    try {
      await finish(
        baseUrlApi,
        {
          level,
          gameid: words?.id,
          gamedata: {
            tiempo: timeRef.current,
            palabras: attempts.current,
          }
        }
      )

      await fetchGame()

    } catch (error) { }

  }

  const finishGameRef = React.useRef(finishGame)
  finishGameRef.current = finishGame

  const changeItem = async (key) => {
    if (status !== STATUS_PLAYING) {
      return
    }

    if (key === 'Backspace' && currentWord.current.length === 0) {
      return
    }
    if (key === 'Backspace' && currentWord.current.length > 0) {
      currentWord.current = currentWord.current.slice(0, -1)
      forceUpdate()
      return
    }

    if (key === 'Enter') {
      if (currentWord.current.length < word.length) {
        setToast({
          // title: 'Fila Incompleta',
          title: I18n.getTranslation({pathname: '/' + locale}, 'game.words.messages.filaincompleta'),
          // message: 'Fila Incompleta',
          message: I18n.getTranslation({pathname: '/' + locale}, 'game.words.messages.filaincompleta'),
          className: '!em-bg-gray-700 em-text-white',
          position: 'top',
        })
        return
      }

      if (currentWord.current === word) {
        attempts.current.push(currentWord.current)
        currentWord.current = ''
        await finishGameRef.current()
        setEndGameModalContent(attempts.current, word)
        forceUpdate()
        return

      } else {
        if (await checkWord(currentWord.current)) {
          attempts.current.push(currentWord.current)
          currentWord.current = ''
          setLettersStatus(attempts.current, word)
          forceUpdate()
        } else {
          setToast({
            // title: '¡No encontrada!',
            title: I18n.getTranslation({pathname: '/' + locale}, 'game.words.messages.noencontrada'),
            message: I18n.getTranslation({pathname: '/' + locale}, 'game.words.messages.noencontrada'),
            // message: '¡No encontrada!',
            className: '!em-bg-danger em-text-white',
            position: 'top',
          })
          setNotFound(true)
          setTimeout(() => {
            currentWord.current = ''
            setNotFound(false)
          }, 800)
          forceUpdate()
        }
        return

      }
    }

    if (currentWord.current.length >= word.length) {
      return
    }

    currentWord.current += key.toUpperCase()
    forceUpdate()
  }

  const timeRef = React.useRef(time)
  timeRef.current = time

  const saveGame = async (exit = false, newUrl = null, auto = false) => {

    const fnc = auto ? autoSave : save

    try {
      await fnc(
        baseUrlApi,
        {
          level,
          gameid: words?.id,
          gamedata: {
            palabras: attempts.current,
            tiempo: timeRef.current,
          },
        },
        exit,
        newUrl,
      )

    } catch (error) { }

  }

  const restartAllData = async () => {
    setWords({})
    setHowToPlay(false)
    _setEndGameModalContent(null)
    setStatistics([])
    setWord('')
    setNotFound(false)
    lettersStatus.current = letters[locale].reduce(
      (acc, letter) => ({
        ...acc,
        [letter.key]: '',
      }),
      {},
    )
    currentWord.current = ''
    attempts.current = []
    setCurrentWord.current = ''
    await fetchStatistics()
    await initGame(fetchGame)
  }

  React.useEffect(() => {
    restartAllData()
  }, [baseUrlApi, id, level]) // eslint-disable-line

  const setLettersStatus = (attempts, word) => {
    const _lettersStatus = {}
    attempts.forEach((attempt) => {
      const status = getStatuses(attempt, word)

      attempt.split('').forEach((letter, index) => {
        const newStatus = status[index]
        const oldStatus = _lettersStatus[letter] || 'none'
        if (oldStatus === 'none') {
          _lettersStatus[letter] = newStatus
          return
        }

        if (oldStatus === 'exact') return
        if (oldStatus === 'included' && newStatus !== 'exact') return
        if (oldStatus === 'wrong' && !['included', 'exact'].includes(newStatus)) return

        _lettersStatus[letter] = newStatus
      })
    })
    lettersStatus.current = _lettersStatus
  }

  const formatDate = (date) => {
    let dateText = moment(date).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  const helpMenu = [
    {
      label: I18n.getTranslation({pathname: '/' + locale}, 'game.words.howToPlay'),
      href: '',
      onClick: () => setHowToPlay(true),
    },
  ]

  return (
    <Layout isLoading={isLoading}>
      <Header />
      <div className='!em-bg-danger' />
      <Game
        statsUrl={`/user/stats${baseUrlApi}`}
        endGameModal={{
          isOpen: showEndGameModal,
          title: title,
          icon: bigIconSrc,
          titleBgColor: bgColor,
          content: endGameModalContent,
          onDate: formatDate(words?.publicado),
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((words?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((words?.promediogeneral || 0) * 1000).format('mm:ss')
            },
          ],
          shareUrl: `${gamesApiBase}user/stats${baseUrlApi}/share?userid=${getUserId()}&id=${words?.id}&level=${level}`,
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        title={title}
        statistics={statistics}
        publicationDate={moment(words?.publicado).format('dddd, DD.MM.YY')}
        expirationDate={moment(words?.publicado).format('dddd, DD.MM.YY')}
        historicalGames={{
          active: !isLoading,
          url: `${baseUrlApi}/getlist?${level ? `level=${level}` : ''}`,
          activeId: words?.id,
          to: baseUrl,
          icon: bigIconSrc,
        }}
        saveGame={() => saveGame()}
        onClickOutside={(newUrl) => saveGame(true, newUrl)}
        exitGame={status !== STATUS_FINISHED ? e => { saveGame(true); e.preventDefault() } : false}
        leftMenu={[
          {
            href: '',
            label: <I18n t='game.actions.help' />,
            options: helpMenu,
          },
        ]}
        helpMenu={helpMenu}
        middleMenu={
          <div className='em-flex em-items-center'>

            <Timer
              setPause={setPause}
              status={status}
              time={time}
            />
          </div>
        }
      >
        <Overflow active={howToPlay}>
          <Alert onClose={() => setHowToPlay(false)}>
            <div className='em-text-center !em-font-semibold em-mb-5'>
              <I18n t='game.words.howToPlay' />
            </div>
            <div className='em-text-center'>
              <I18n t='game.words.howToPlayContent' />
            </div>
            <div className='em-text-center em-uppercase'>
              <I18n t='game.words.examples.title' />
            </div>

            <div className='em-flex em-justify-center em-space-x-2 em-text-center em-uppercase'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.first.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='em-bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 0 ? 'exact' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='em-text-center'>
              <I18n t='game.words.examples.first.explanation' />
            </div>
            <div className='em-flex em-justify-center em-space-x-2 em-text-center em-uppercase'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.second.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='em-bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 2 ? 'included' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='em-text-center'>
              <I18n t='game.words.examples.second.explanation' />
            </div>
            <div className='em-flex em-justify-center em-space-x-2 em-text-center em-uppercase'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.third.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='em-bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 4 ? 'wrong' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='em-text-center'>
              <I18n t='game.words.examples.third.explanation' />
            </div>

            <div className='em-text-center !em-font-semibold'>
              <I18n t='game.words.footer' />
            </div>
            <div className='em-text-sm em-text-center'>
              <I18n t='game.words.disclaimer' />
            </div>
          </Alert>
        </Overflow>

        {message}
        <div className='em-relative em-max-w-xl em-p-6 em-pt-3 em-m-auto em-mt-6 em-text-center em-justify-items-start em-pb-52'>
          {
            (
              [2, 3].includes(words?.estadouser?.id) &&
              !words?.estadouser?.palabras?.includes(words?.palabra?.toUpperCase())
            ) && (
              <div className='em-absolute em-z-10 em-flex em-flex-col em-space-y-2 -em-translate-x-1/2 em-left-1/2 -em-top-3'>
                <div className='em-text-sm em-font-semibold'><I18n t='game.words.messages.palabracorrecta' /></div>
                <div className='em-px-4 em-py-2 em-text-white em-uppercase em-bg-black em-rounded-lg em-shadow-lg'>{words.palabra}</div>
              </div>
            )
          }
          <Grid
            attempts={attempts.current}
            currentWord={currentWord.current}
            notFound={notFound}
            solution={word}
            finished={status === STATUS_FINISHED}
            cellComponent={props.cellComponent}
          />
          {
            status === STATUS_PLAYING &&
            <Keyboard
              className='justify-content-center'
              onClick={changeItem}
              locale={locale}
              lettersStatus={lettersStatus.current}
            />
          }
        </div>

        {modal}

      </Game>
    </Layout>
  )
}

export default function Words(props) {
  const siteStore = useSiteStore()

  const getCellTypeBySiteName = (name) => {
    switch (name) {
      case 'lavanguardia.com':
      case 'THE NEWSPAPER':
        return BorderlessCell
      default:
        return Cell;
    }
  }

  return (
    <WordsInner
      cellComponent={getCellTypeBySiteName(siteStore.site.name)}
      numberRowComponent={() => null}
      {...props}
    />
  )
}

WordsInner.propTypes = {
  gameProps: propTypes.object,
  type: propTypes.string,
}

WordsInner.defaultProps = {
  type: 'words',
}
