import {IoMdShare} from 'react-icons/io'

export const fr = {

    close: 'Fermer',
    by: 'Par',
    play: 'Jouer',
    game: {
        historicalGames: {
            playing: 'AU PIQUET',
        },
        new: 'Nouveau',
        actions: {
            save: 'Sauvegarder',
            download: 'Télécharger',
            exit: 'Sortie',
            help: 'Aide'
        },
        timer: {
            show: 'Afficher',
            hide: 'Cacher',
        },
        yourPoints: 'Vos points',
        heptacracks: 'Heptacracks',
        yourWords: 'Vos mots',
        yourAnswers: 'Vos réponses',
        send: 'Envoyer',
        tusclics: 'Vos clics',
        messages: {
            init: {
                message: 'Êtes-vous prêt.e à commencer?',
                button: 'Jouer',
            },
            pause: {
                message: 'Le jeu est en pause',
                button: 'Continuer',
            },
            restart: {
                message: 'Vous êtes sur le point de recommencer le jeu',
                buttonPrimary: 'Redémarrage',
                buttonCancel: 'Retour à',
            },
            resolve: {
                message: 'Si vous choisissez RESOLVE, ce jeu sera considéré comme incomplet dans Mes statistiques. Êtes-vous satisfait ?',
                buttonPrimary: 'Oui',
                buttonCancel: 'Non',
            },
            save: {
                message: 'Vous êtes sur le point de quitter le jeu, voulez-vous enregistrer vos modifications ?',
                buttonPrimary: 'Oui',
                buttonCancel: 'Non',
            },
            errorSaving: {
                message: 'Erreur d\'enregistrement. Veuillez réessayer.',
                button: 'Fermer',
            },
            errorRestarting: {
                message: 'Erreur de redémarrage du jeu. Veuillez réessayer.',
                button: 'Fermer',
            },
            errorResolving: {
                message: 'Erreur de résolution du jeu. Veuillez réessayer.',
                button: 'Fermer',
            },
            errorFinishing: {
                message: 'Erreur à la fin du jeu. Veuillez réessayer.',
                button: 'Fermer',
            },
            errorChecking: {
                message: 'Dommage ! Vous n\'avez pas réussi à terminer le jeu. Continuez d\'essayer',
                button: 'Fermer',
            },
            finished: {
                message: 'Vous avez terminé ce jeu. Vous ne pouvez que le visualiser.',
                button: 'Retour',
            },
            complete: {
                title: 'Bravo !',
                endGameTitle: 'JEU FINI!',
                averageTime: 'Temps moyen global',
                percentageGeneralCompleted: 'Pourcentage global terminé',
                completedTime: 'Partie terminée en',
                share: 'Partagez vos résultats',
                shortDescription: 'Vérifiez si vous vous êtes amélioré dans Mes statistiques',
                description: 'Vous avez terminé le jeu. Vérifiez si vous avez progressé dans Mes statistiques',
                button: 'Fermer',
                onDate: (date) => <>Jeu le {date}</>,
            },
            incomplete: {
                message: 'Voici le jeu résolu. Dans Mes statistiques, elle sera considérée comme incomplète',
            },
            past: {
                message: 'Le jeu est terminé. Vous pouvez seulement le voir.',
                button: 'Retour',
            },
            saved: {
                // message: 'Vous n'avez plus besoin d'utiliser le bouton Enregistrer. Chaque fois que vous avancez, le jeu est automatiquement sauvegardé.',
                message: 'Le jeu a été sauvegardé avec succès',
                button: 'Quitter',
            },
            savedlexireto: {
                message: ({date}) => <>Le jeu a été sauvegardé avec succès</>,
                button: 'Quitter',
            }
        },
        currentChallenge: {
            actions: {
                next: 'Suivant',
                showExplain: 'Voir l\'explication',
                finish: 'Fin',
            },
            messages: {
                finish: {
                    title: 'Test terminé !',
                    description: ({corrects, errors}) => <>Réponses correctes {corrects}, incorrectes {errors}.<br/>Vous
                        pouvez vérifier vos résultats cumulés dans Mes statistiques.</>,
                    button: 'Fermer',
                },
                showExplain: 'La raison de la réponse',
            },
            statusEndGame: {
                good: 'BRAVO !',
                neutral: 'BON !',
                bad: 'VOUS POUVEZ FAIRE MIEUX !',
            },
        },
        crosswords: {
            questions: 'Questions de mots croisés',
            horizontals: 'Horizontaux',
            verticals: 'Verticaux',
            horizontal: 'Horizontaux',
            vertical: 'Verticaux',
            messages: {
                finish: {
                    title: 'Bravo !',
                    description: 'Vous allez finir vos mots.\nVérifiez si vous avez progressé dans les statistiques.',
                    button: 'Sortez',
                },
                incomplete: {
                    title: '',
                    description: 'Ici, vous avez résolu les mots que vous avez écrits. Mes statistiques seront comptabilisées comme incomplètes.',
                    button: 'Sortez',
                },
            },
            actions: {
                checkLetter: 'Vérifiez la lettre',
                checkWord: 'Vérifier le mot',
                checkCrossword: ({gameName}) => <>Vérifier {gameName}</>,
                resolveCrossword: 'Résoudre',
                restartCrossword: 'Recommencer',
            }
        },
        sudoku: {
            candidates: 'Possible',
            levels: {
                easy: 'De base',
                medium: 'Avancé',
                hard: 'Expert',
            },
            messages: {},
            actions: {
                checkCell: 'Case à cocher',
                checkSudoku: ({gameName}) => <> Vérifier {gameName}</>,
                resolveSudoku: 'Résoudre',
                restartSudoku: 'Recommencer',
            }
        },
        letrasConectadasGame: {
            title: "Lettres",
            actions: {
                checkGame: "Checklists",
                resolveGame: "Résolution",
                restartGame: "Redémarrer",
                apply: "Appliquer",
                clean: "Supprimer "
            },
            messages: {
                saved: {
                    message: ({date}) => <> Le jeu a été sauvegardé avec succès. Vous pourrez le terminer
                        avant le <strong>{date}</strong></>
                },
                letters: "Lettres",
                howToPlayTitle: "Comment jouer ?",
                howToPlayText1: "Trouvez les sept mots que nous avons cachés en sélectionnant les cases adjacentes dans toutes les directions, sauf en diagonale.",
                howToPlayText12: "Vous pouvez utiliser chaque lettre autant de fois que vous le souhaitez, mais pas dans le même mot.",
                howToPlayText2:
                    "Note: Tous les mots que vous pouvez créer ne seront pas valides. Seuls ceux que nous suggérons le seront",
                howToPlayTextFull:
                    " Trouvez les sept mots que nous avons cachés en sélectionnant les cases adjacentes dans toutes les directions, sauf en diagonale. Vous pouvez utiliser chaque lettre autant de fois que vous le souhaitez, mais pas dans le même mot. Veuillez noter que tous les mots que vous pouvez créer ne seront pas corrects. Seuls ceux que nous vous suggérons le seront.",
                correctword: "Le bon mot !",
                wrongword: "Mot erroné !",
                repeatedword: "Repeatedword !"
            }
        },
        differencesGame: {
            title: "8 erreurs",
            hits: "hits",
            clicks: "clics",
            resolveGame: "Résolution",
            restartGame: "Redémarrer",
            howToPlay: "Comment jouer",
            howToPlayText: () => <> Trouvez et indiquez 8 différences entre la première et la deuxième image. <br/> Vous avez 12 tentatives.</>,
            howToPlayTextFull:
                () =>
                    <>
                        <strong>Comment jouer</strong>
                        <br/>
                        Trouvez et indiquez 8 différences entre la première et la deuxième image. <br/> Vous avez 12 tentatives.
                    </>,
            successfullyHit: "BIEN !",
            incorrectHit: "ERREUR !",
            clickOtherImage: "Cliquez sur la deuxième image",
            completadotitulo: "Jeu terminé !",
            textoacertado: "Clics réussis",
            textoerroneos: ", clics incorrects",
            textorevisarestadisticas: "Vous pouvez consulter les résultats cumulés dans Mes statistiques",
            statusEndGame: {
                good: "BIEN !",
                neutral: "BON !",
                bad: "VOUS POUVEZ FAIRE PLUS ! ",
            }
            // description: ({ corrects, errors }) => <>Réponses correctes {corrects}, incorrectes {errors}.<br />Les résultats cumulés peuvent être vérifiés dans Mes statistiques.</>,
            //  complété: ({successful}) => <>Clics réussis {successful}, clics erronés, clics erronés.<br/>Vous pouvez vérifier les résultats cumulés dans Mes statistiques.</>,

        },
        tilesGame: {
            title: "Tiles",
            totalPairs: "Total Pairs",
            resolvedPairs: "Mes pairs",
            marker: "Tableau de bord",
            resolveGame: "Résolution",
            restartGame: "Redémarrer",
            howToPlay: "Comment jouer",
            howToPlayText: "Associez des tuiles identiques pour compléter le panneau avant la fin du temps imparti - vous avez 4 minutes ! Attention, certaines tuiles peuvent être tournées.",
            howToPlayTextFull:
                () =>
                    <>
                        <strong>Comment jouer</strong>
                        <br/>
                        Associez les tuiles identiques pour compléter le panneau dans le temps le plus court possible. Vérifier
                        certains carreaux peuvent être tournés.
                    </>,
            incorrectlyPaired: "Mauvais partenaire !",
            successfullyPaired: "La bonne paire !",
            messages: {
                init: 'Vous avez 4 minutes pour terminer',
                '3m': '3 minutes restantes',
                '2m': '2 minutes restantes',
                '1m': '1 minute restante',
                '30s': '30 secondes restantes',
                '10s': '10 secondes restantes',
            },
            incomplete: {
                title: 'TRUST !',
                message: 'Vous n\'avez plus le temp',
            }
        },
        LexiRetoGame: {
            expirationDate: 'aujourd\'hui à 23:59:59',
            title: "Le défi de Lexi",
            resolveGame: "Résolution",
            restartGame: "Reiniciar",
            howToPlay: "Comment jouer",
            howToPlayText: "Comment jouer",
            howToPlayTextFull:
                () => <>
                    <div className="mb-1"><strong>Comment jouer</strong></div>
                    <ul>
                        <li>Formez des mots comprenant au minimum 3 lettres. Vous pouvez répéter des lettres, mais
                            toujours en incluant la lettre centrale.
                        </li>
                        <li>Les noms propres, les pluriels et les formes verbales autres que les infinitifs ne sont pas
                            autorisés.
                        </li>
                        <li>Trouvez des mots comprenant 7 lettres (Heptacrack !) et vous remonterez dans le classement
                            et améliorerez vos statistiques.
                        </li>
                        <li>Score : les mots comprenant 3 lettres donnent 1 point et ceux de 4 lettres, 2 points. A
                            partir de 5 lettres, vous obtiendrez 1 point pour chaque lettre du mot.
                        </li>
                        <li>Les heptacracks donnent 10 points.</li>
                        <li>ATTENTION. Vous ne pouvez résoudre les Lexi Challenges que le jour même de leur publication.
                            A 12h00 le jeu devient inactif et il est uniquement possible de le consulter.
                        </li>
                    </ul>
                </>,
            totalpalabras: "Vos mots",
            tuspalabras: "Vos mots",
            empiezanpor: "Ils commencent par : ",
            encontradas: "Trouvée",
            heptacrackencontradas: "Heptacracks",
            comovatujuego: "Comment se déroule le jeu?",
            comovatujuego_heptacrack: "Heptacrack!",
            comovatujuego_nuevonivel: "Vous êtes promu !",
            marcador: "Tableau d'affichage",
            puntuacion: "Vos points",
            maximo: "Maximo",
            encontrados: "Trouvé",
            actions: {
                apply: "Envoyer",
                clean: "Supprimer",
                reordenar: "Changer l'ordre"
            },
            messages: {
                correctword: "Bien !",
                wrongword: "Pas trouvé",
                repeatedword: "Déjà trouvé",
                minimo3letras: "Minimum 3 lettres",
                faltaletracentral: "Lettre du milieu manquante"
            },
            homenuevo: "NOUVEAU",
            statusEndGame: {
                faces_6_7: 'SUPERFUNCTION !',
                faces_4_5: 'SUPER !',
                faces_2_3: 'BON NIVEAU !',
                faces_0_1: 'VOUS POUVEZ FAIRE PLUS !',
            }


        },
        buttons: {
            statistics: 'Mes statistiques',
            share: () => <><IoMdShare/> <span>Partager</span></>,
            shareLabel: 'Partager',
        },
        words: {
            howToPlay: 'Comment jouer',
            howToPlayContent: 'Trouvez le mot caché en 6 essais. Chaque essai doit être un mot valide de 5 lettres. Après chaque essai, la couleur des lettres vous donne des indices sur le mot caché. Les noms propres, les pluriels et les verbes conjugués ne sont pas autorisés (seulement l\'infinitif).',
            examples: {
                title: 'Exemples',
                first:
                    {
                        word: 'CUIVRE',
                        explanation:
                            'La lettre C est dans le mot et à la bonne place.',
                    }
                ,
                second: {
                    word: 'MARS', explanation: 'La lettre R est dans le mot mais à la mauvaise place.',
                }, third: {
                    word: 'VERT', explanation: 'La lettre E n\'est pas dans le mot.',
                },
            },
            messages: {
                filaincompleta: 'Rangée incomplète',
                noencontrada: 'Non trouvée !',
                palabracorrecta: "Le mot correct est :",
            },
            endGame: {
                puedesMejorar: 'VOUS POUVEZ VOUS AMÉLIORER !',
                insuperable: 'INCROYABLE !',
                fantástico: 'FANTASTIQUE !',
                excelente: 'EXCELLENT !',
                bravo: 'BRAVO !',
            }
            ,
            footer: 'UN NOUVEAU MOT CHAQUE JOUR !',
            disclaimer: 'The Word est une adaptation de l\'entraînement mental du jeu original créé par Josh Wardle (@powerlanguish).',
            fourLetters: 'THE HIDDEN WORD 4',
            fiveLetters: 'THE HIDDEN WORD 5',
            sixLetters: 'THE HIDDEN WORD 6',
            choose: 'Choisir',
        },
        quiz: {
            pregunta: 'Question',
        }
    },


}
